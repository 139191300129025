import { render, staticRenderFns } from "./SygniCircleStatusButton.vue?vue&type=template&id=517930df&scoped=true&"
import script from "./SygniCircleStatusButton.vue?vue&type=script&lang=ts&"
export * from "./SygniCircleStatusButton.vue?vue&type=script&lang=ts&"
import style0 from "./SygniCircleStatusButton.vue?vue&type=style&index=0&id=517930df&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517930df",
  null
  
)

export default component.exports