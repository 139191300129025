import ContextHelper from '@/modules/shared/utils/ContextHelper';
import { SelectOption } from '@/store/types';
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class SygniAccess extends Vue {
  get accessModules() {
    return this.$store.getters['genprox/getAccessModules'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get isFundManager() {
    return this.$route.path.includes('fund-manager') ? true : false;
  }

  get isFundManagerRole() {
    return ContextHelper.isActiveContextFundManager
  }

  get fundOptions(): Array<SelectOption<string>> {
    const funds = this.$store.getters['auth/getInvestedFunds'];

    if (!funds) return [];

    return funds.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    });
  }

  get companyOptions(): Array<SelectOption<string>> {
    const companies = this.$store.getters['auth/getInvestedCompanies'];

    if(!companies) return [];

    return companies.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get options(): Array<SelectOption<string>> {
    return this.isPortfolioCompany ? this.companyOptions : this.fundOptions;
  }

  get showLePicker() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' && this.options.length > 1;
    } else {
      return this.activeUserData.context.context !== 'fund' && this.options.length > 1;
    }
  }

  get useViewedId() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company';
    } else {
      return this.activeUserData.context.context !== 'fund';
    }
  }

  async loadMissingData() {
    if(!this.isPortfolioCompany) {
      if (this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
        await this.$store.dispatch('auth/getInvestedFunds');
      } else {
        this.$store.commit('auth/setViewedFund', '')
      }
    } else {
      if(this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/getViewedCompany');
        await this.$store.dispatch('auth/getInvestedCompanies');
      } else {
        this.$store.commit('auth/setViewedCompany', '')
      }
    }
  }

  async beforeMount() {
    await this.loadMissingData();
  }
}
