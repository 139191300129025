var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-circle-status-button"
  }, [_vm.status.toUpperCase() === _vm.Statues.active ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/accept.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.rejected ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/reject.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.closed ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/accept.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.awaiting ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/question.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.pending || _vm.status.toUpperCase() === _vm.Statues.new ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/pending.svg")
    }
  }) : _vm._e(), _vm.status.toUpperCase() === _vm.Statues.declined ? _c('img', {
    attrs: {
      "src": require("../../assets/icons/decline.svg")
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }